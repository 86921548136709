.Table__itemCount {
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-start;
  padding: 20px 60px;
  background: #fff;
}

.Table__pageButton {
  font-size: 14px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #52535a;
  margin: 0 4px;
}

.Table__visiblePagesWrapper {
  margin: 0 13px;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}
